// App.js or any other component file
import React from 'react';
import './App.css'; // Import your CSS file

class OpenFacebookButton extends React.Component {
  openFacebook = (event) => {
    event.preventDefault(); // Prevent the default action of the anchor tag
    // Create the Facebook URL
    const facebookUrl = 'nextlearning://';

    // Attempt to open the Facebook app
    window.location.href = facebookUrl;
  };

  render() {
    return (
      <button onClick={this.openFacebook} className="btn-purple btn-lg"> {/* Changed class to btn-purple */}
        Open App
      </button>
    );
  }
}

export default OpenFacebookButton;
