import React from 'react';
import OpenAppButton from './OpenAppButton';
const NewComponent = () => {
  return (
    <div>
      <meta charSet="utf-8" />
      <title>Successfully Confirmed!</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.0/dist/css/bootstrap.min.css" rel="stylesheet" />
      <style type="text/css">
        {`
          body{margin-top:20px;}
          .mail-success {
            text-align: center;
            background: #fff;
            border-top: 1px solid #eee;
          }
          .mail-success .success-inner {
            display: inline-block;
          }
          .mail-success .success-inner h1 {
            font-size: 100px;
            text-shadow: 3px 5px 2px #3333;
            color: #A435F0;
            font-weight: 700;
          }
          .mail-success .success-inner h1 span {
            display: block;
            font-size: 25px;
            color: #333;
            font-weight: 600;
            text-shadow: none;
            margin-top: 20px;
          }
          .mail-success .success-inner p {
            padding: 20px 15px;
          }
          .mail-success .success-inner .btn {
            color: #fff;
          }
        `}
      </style>
      <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
      <section className="mail-success section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-12">
              <div className="success-inner">
                <h1><i className="fa fa-envelope" /><span>Successfully Confirmed!</span></h1>
                <p>Please open Next Learning App and login with email and password you provided when you are signing up.</p>
                <p>Thank You for Confirmation.</p>
                <OpenAppButton/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NewComponent;